@import url(https://fonts.googleapis.com/css2?family=Varela+Round&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Varela Round', sans-serif;
}
body {
    background-image: linear-gradient(rgba(0, 0, 0, .3),rgba(0, 0, 0, 0.5)),  url(/static/media/acnh-bg.1e329407.png);
}
header{
    background-color: 	#4d97d6;
    -webkit-filter: drop-shadow(0 5px 4px rgba(0,0,0,0.3));
            filter: drop-shadow(0 5px 4px rgba(0,0,0,0.3));
}
header h1 {
    color: rgba(255,255,255,0.9);
    margin: 0;
    padding: 25px;
}
header .logo {
    width: 42px;
    vertical-align: bottom;;
}

h4 {
    color: #fff;
    margin-left: 15px;
    padding-bottom: 0;
    margin-bottom: 0;
}
.main {
    display: flex;
    flex-wrap: wrap;
}
.col {
    background: rgba(255,255,255,0.9);
    border-radius: 40px;
    -webkit-filter: drop-shadow(10px 10px 4px rgba(0,0,0,0.3));
            filter: drop-shadow(10px 10px 4px rgba(0,0,0,0.3));
    margin: 20px 40px;
    min-width: 200px;
    padding: 0 20px 20px 20px;
}

.col table {
    border-radius: 12px;
    overflow: hidden;
}

th {
    background-color: #4d97d6;
    color: #ffffff;
    text-align: left;
    padding: 5px;
}
td {
    padding: 5px;
}

.table-sea tr,
.table-best tr {
    background-color: rgba(53,188,173, 0.2);
}
.table-bugs tr,
tr.bug {
    background-color: rgba(193,140,73, 0.2);
}
.table-fish tr,
tr.fish {
    background-color: rgba(77,151,214, 0.2);
}

@media only screen and (max-width: 1140px) {
    .col {
        margin-left: 10px;
        margin-right: 10px;
    }
    .col:not(:first-child) {
        margin-top: 75px;
    }
    .col table {
        width: 100%;
    }
}
